export const bazeUrl = "https://api.movies-for-study.online";
//export const bazeUrl = "http://localhost:3001";
export const moviesUrl = "https://api.nomoreparties.co/beatfilm-movies";
export const urlForPosters = "https://api.nomoreparties.co/";

export const ERROR_MESSAGE = {
  DATA_RECEIVE_ERROR: 'Во время запроса произошла ошибка. Возможно, проблема с соединением или сервер недоступен. Подождите немного и попробуйте ещё раз.',
  SIGNUP_ERROR: 'При обработке данных что-то пошло не так. Проверьте введенные данные и попробуйте еще раз.',
  AUTH_ERROR: 'Что-то не так с авторизацией. Пожалуйста, войдите в свой профиль.',
  SOMETHING_WRONG: 'Ошибка по умолчанию.',
  SAME_EMAIL: 'Такой email уже существует.'
};

export const URL_PATTERN = /^:?https?:\/\/(www\.)?[a-zA-Z\d-]+\.[\w\d\-.~:/?#[\]@!$&'()*+,;=]{2,}#?$/;

